import Header from '../features/header';
import "../App.css";


export default function RecordStore() {
    return (
        <>
            <Header />
            <div className="pageBackground">
                <div className="container text-white">
                    <h1 className="text-white">The Record Store&trade;</h1>
                    <p>The Record Store is a throwback to the days when you could 
                        flip through records and find what you were looking for and the occational
                        new gem that got you excited.</p>
                    <p>We use flip through technology to allow you to flip through albums, make your selections, or singles
                        and then checkout.
                    </p>
                    <p>We allow all artists, and labels to list their albums and selected singles and merchandise
                         for sale with sound clips and images, so you can have an immersive experience.
                    </p>
                    <p>Initially The Record Store will be available for your phones and devices, but we are also working on
                        kiosks that can be placed in stores, and other locations. 
                    </p>
                    <p>We want bands and musicians to sell their products wherever they can, but we think that
                        The Record Store will be a great place to start.
                    </p>
                </div>
            </div>
        </>
    );
}