import "../App.css";
import logo from '../assets/images/splinge_on_blue.png';
import { useStore } from '../store/store';


export default function Header() {  

    const setPage = useStore((state) => state.setPage);

    function handlePageChange(newPage:string) {
        console.log(newPage);
        setPage(newPage);
    }

    return (
        <div className="header">
            <div className = "row">
                <div className="col-4">
                    <img src={logo} alt="Logo" className="logo" />
                </div>
                <div className="col-8 text-end text-black text-middle">
                    <button className="headerButton" onClick={() => handlePageChange("home")}>Home</button>
                    <button className="headerButton" onClick={() => handlePageChange("about")}>About</button>
                    <button className="headerButton" onClick={() => handlePageChange("contact")}>Contact</button>
                </div>
            </div>
        </div>
    );

}