import Header from '../features/header';
import "../App.css";


export default function About() {
    return (
        <>
            <Header />
            <div className="pageBackground">
                <div className="container text-white">
                    <h1 className="text-white">About</h1>
                    <h4 className="text-white font-italic">
                        Splinge has a mission to help musicians monetize their art as they see fit!
                    </h4> 
                    <p>
                        Splinge is a platform that allows musicians to monetize their art in a way that is meaningful to them. 
                        We believe that musicians should be able to choose how they want to monetize their art, 
                        and we provide the tools to make that happen.
                    </p>
                </div>
            </div>
        </>
    );
}