import React, { useState } from 'react';
import Header from '../features/header';
import "../App.css";
import { useStore } from '../store/store';


interface ContactData {
    name: string;
    email: string;
    message: string;
    [key: string]: string; // Add index signature
}

interface contactResponse {
    name: string;
    email: string;
    message: string;
    _id: string;
}

export default function App() {

    const setPage = useStore((state) => state.setPage);

    const [formData, setFormData] = useState<ContactData>({
        name: '',
        email: '',
        message: '',
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    async function fetchConditionally() {
        try {

            const formBody = Object.keys(formData)
                .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(formData[key]))
                .join('&');

            console.log('body: ', formBody);

            //const response2 = await fetch('https://api.splinge.com/contact'
            const response2 = await fetch('https://api.splinge.com/contact'
                , {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: formBody,
                }
            );
            const data2 = await response2.json() as Promise<contactResponse>
            console.log('Data :', await data2);

        } catch (error) {
            console.log('Error:', error);
        }
        setPage('confirm');
    }

    return (
        <>
            <Header />

            <div className="pageBackground">
                <div className="container text-white">
                    <h3>Contact:</h3>
                    <br />
                    <div className="row">
                        <div className="col-3"></div>
                        <div className="col-6">
                            <div className="row">
                                <div className="col-3">
                                    Name:
                                </div>
                                <div className="col-9">
                                    <input
                                        className="w-100"
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    Email:
                                </div>
                                <div className="col-9">
                                    <input
                                        className="w-100"
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    Message:
                                </div>
                                <div className="col-9">
                                    <textarea
                                        className="w-100"
                                        name="message"
                                        value={formData.message}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3"></div>
                                <div className="col-9">
                                    <button type="button" onClick={fetchConditionally}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}