import Header from '../features/header';
import "../App.css";
import { useStore } from '../store/store';


export default function Home() {

    const setPage = useStore((state) => state.setPage);

    function handlePageChange(newPage:string) {
        console.log(newPage);
        setPage(newPage);
    }

    return (
        <>
            <Header />
            <div className="mainBackground">
                <div className="container text-white">

                    <div className="row">
                        <div className="col-4 text-white">
                            <h3>Mission:</h3>
                            <p className="font-italic">Splinge has a mission to help musicians monetize their art as they see fit.</p>
                            <h3>Record Store</h3>
                            <p>Our first project is the Record Store&trade;.  This will be released to the public in the coming weeks.
                                The Record Store&trade; will allow musicians to sell their music directly to their fans.
                                The Record Store&trade; will also allow fans to purchase music directly from their favorite musicians.</p>
                            <p>This moves the industry away from streaming services that keep the lions share of the money for themselves
                                and towards a model where the musicians get their fare share of the money.</p>

                            <p><button className="headerButton" onClick={() => handlePageChange("recordstore")}>Learn more...</button></p>
                            <p><br /><br /></p>
                        </div>
                        <div className="col-4"></div>
                        <div className="col-4 text-white">
                            <h3>What's next?</h3>
                            <p>We have several patent pending technologies that will make the music industry more artist-centric.</p>
                            <p>These will be anounced and released in the near future.</p>

                        </div>
                    </div>


                </div>
            </div>
        </>
    );
}