import React, { useState } from 'react';
import Header from '../features/header';
import "../App.css";

export default function Confirm() { 
    return (
        <>
            <Header />
            <div className="container">
                <h1>Thank you for your message!</h1>
                <p>We will get back to you as soon as possible.</p>
            </div>
        </>
    );
}